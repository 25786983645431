import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import React from 'react';
import { InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { Box } from '@mui/material';
import Homepage from 'components/views/homepage';
import { getStaticProps as _getStaticProps } from 'lib/blog/getStaticProps';
export const getStaticProps = _withSuperJSONProps(_getStaticProps, []);
function HomePage({
  posts
}: InferGetStaticPropsType<typeof getStaticProps>) {
  return <Box>
      <Head>
        <title>Cookie3 Analytics | AI-powered Google Analytics for Web3</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content="Analyze website traffic and on-chain conversion. Know what projects your audience interacts with. Learn from successful projects data. Exclude bots from your airdrop." />
      </Head>
      <Homepage posts={posts} />
    </Box>;
}
export default _withSuperJSONPage(HomePage);