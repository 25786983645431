import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from 'src/utils';

const variants = cva(
  [
    'flex items-center justify-center w-max font-sans font-medium text-black antialiased',
    '!outline-none focus:ring-2 focus:ring-ds-border-focus focus:ring-offset-1 focus-visible:!outline-none',
    'transition-all',
    'disabled:cursor-not-allowed disabled:text-ds-text-tertiary disabled:bg-ds-quarternary',
    '[&>svg]:w-[1em] [&>svg]:h-[1em]',
  ],
  {
    variants: {
      intent: {
        brand: [
          'bg-ds-brand-primary hover:bg-ds-brand-secondary active:bg-ds-brand-tertiary',
        ],
        brand2: [
          'bg-ds-brand2-primary hover:bg-ds-brand2-secondary active:bg-ds-brand2-tertiary',
        ],
        brand3: [
          'text-text-onbrand3 bg-ds-brand3-primary hover:bg-ds-brand3-secondary active:bg-ds-brand3-tertiary',
        ],
        neutral: [
          'text-ds-button-text-default bg-ds-button-bg-default hover:bg-ds-button-bg-hover active:bg-ds-button-bg-pressed',
        ],
        banner: [
          'text-text-primary bg-ds-primary hover:bg-ds-secondary active:bg-ds-tertiary',
        ],
        inverted: [
          'text-ds-text-inverted-primary bg-ds-inverted-primary hover:bg-ds-inverted-secondary active:bg-ds-inverted-tertiary',
        ],
        danger: 'text-ds-text-inverted-primary bg-ds-red-400 hover:opacity-90',
      },
      size: {
        l: ['h-12 gap-2 rounded-ds_s px-5 text-lg/none'],
        m: ['h-10 gap-1.5 rounded-ds_s px-4'],
        s: ['h-8 gap-1 rounded-ds_s px-3 text-sm'],
      },
    },
    defaultVariants: {
      intent: 'brand',
      size: 'l',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof variants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, intent, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(variants({ intent, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = 'Button';

export { Button };
