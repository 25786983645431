import { PiCaretRight } from 'react-icons/pi';
import Link from 'next/link';
import { HelpCircle, ListTodo, UsersRound } from 'lucide-react';
import { Button } from 'src/shared/ui/button';

export default function GetMoreWidget() {
  return (
    <div className="rounded-xl bg-ds-inverted-primary p-8 text-white">
      <p className="text-2xl font-medium text-white">Get more from Cookie3</p>
      <p className="mt-1 text-sm text-ds-text-tertiary">
        Discover what&apos;s Cookie3&apos;s tools and features. Develop and
        manage your platform.
      </p>

      <div className="border-ds-border-quarternary/30 dark mt-3 flex items-center justify-between gap-3 border-b py-4">
        <div>
          <HelpCircle size={16} className="mb-1 text-ds-fg-quarternary" />
          <p>App docs</p>
        </div>
        <Link href="https://docs.cookie3.co/cookie3-docs" target="_blank">
          <Button size={'s'} intent={'neutral'}>
            Read
            <PiCaretRight />
          </Button>
        </Link>
      </div>

      <div className="border-ds-border-quarternary/30 dark flex items-center justify-between gap-3 border-b py-4">
        <div>
          <UsersRound size={16} className="mb-1 text-ds-fg-quarternary" />
          <p>Invite your team</p>
        </div>
        <Link href="/settings/team-managment">
          <Button size={'s'} intent={'neutral'}>
            Read
            <PiCaretRight />
          </Button>
        </Link>
      </div>

      <div className="border-ds-fg-quarternary/30 dark flex items-center justify-between gap-3 border-b py-4">
        <div>
          <ListTodo size={16} className="mb-1 text-ds-fg-quarternary" />
          <p>Import your lists</p>
        </div>
        <Link href="/csv">
          <Button size={'s'} intent={'neutral'}>
            Read
            <PiCaretRight />
          </Button>
        </Link>
      </div>
    </div>
  );
}
