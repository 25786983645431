import { PiCaretRight } from 'react-icons/pi';
import Image from 'next/image';
import Link from 'next/link';
import gsap from 'gsap';
import { Button } from 'src/shared/ui/button';

import Dashboard from 'components/Dashboard';
import Content from 'components/primitives/Content';
import { PostsList } from 'lib/blog/cms/types';
import { useGSAP } from 'lib/gsap/useGsap';

import RafalGrid from '../../../shared/ui/grid/index';
import BlogCard from './Blog/BlogCard';
import AnalyzeOnChain from './NewWidgets/AnalyzeOnChain';
import CookieGAWidget from './NewWidgets/CookieGAWidget';
import FluidConnector from './NewWidgets/FluidConnector';
import GetMoreWidget from './NewWidgets/GetMoreWidget';
import OneStopMarketingWidget from './NewWidgets/OneStopMarketingWidget';
import SegmentAudienceWidget from './NewWidgets/SegmentAudienceWidget';
import TargetedAdsWidget from './NewWidgets/TargetedAdsWidget';

const PARTNERS = [
  'mantle',
  'polkastarter',
  'kyberswap',
  'woo',
  'gameswift',
  'linea',
  'flooz',
] as const;

export default function Homepage({ posts }: { posts: PostsList }) {
  useGSAP(() => {
    const tl = gsap.timeline();
    tl.from('.animate', { opacity: 0, y: 20, stagger: 0.04 });
  });

  return (
    <Dashboard pageName="Homepage">
      <Content>
        <RafalGrid columns={3} gap={'large'}>
          <div className="animate md:col-span-2">
            <OneStopMarketingWidget />
          </div>
          <div className="animate">
            <GetMoreWidget />
          </div>
        </RafalGrid>
        <RafalGrid columns={2} gap="large" className="mt-8 ">
          <div className="animate relative">
            <CookieGAWidget />

            <FluidConnector className="absolute -right-8 bottom-0 top-0 my-auto hidden md:block" />
            <FluidConnector
              className="absolute -bottom-8 right-4 md:left-4"
              isVertical
            />
          </div>

          <div className="animate relative h-full w-full">
            <TargetedAdsWidget />
            <FluidConnector className="absolute left-4" isVertical />
          </div>

          <div className="animate relative">
            <AnalyzeOnChain />

            <FluidConnector
              className="absolute -bottom-8 right-4 md:left-4"
              isVertical
            />
          </div>

          <div className="animate relative h-full">
            <SegmentAudienceWidget />
            <FluidConnector className="absolute -left-8 bottom-0 top-0 hidden md:block" />
          </div>
        </RafalGrid>

        <div className="animate mt-12 flex flex-col items-center rounded-2xl bg-ds-secondary px-3 md:mt-6 md:flex-row md:px-6">
          <p className="text-sm font-medium text-[#333333]">
            Used by <strong>250+</strong> Web3 projects:
          </p>
          <div className="ml-auto mt-6 flex flex-wrap justify-center gap-16 md:mt-0">
            {PARTNERS.map((partner) => (
              <div
                className="relative flex items-center justify-center rounded-lg py-3"
                key={partner}
              >
                <Image
                  src={`/assets/campaigns/peers/${partner}.svg`}
                  objectFit="contain"
                  alt=""
                  width={90}
                  height={30}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="mt-12 h-[1px] w-full bg-[#E1E1E1]"></div>

        <div className="mt-12 flex items-center justify-between">
          <p className="text-2xl font-medium text-neutral-950">
            Latest from our blog
          </p>

          <Link href={`https://cookie3.com/blog`} target="_blank">
            <Button size={'s'} intent="neutral">
              See all articles
              <PiCaretRight />
            </Button>
          </Link>
        </div>
        <RafalGrid columns={3} gap={'xl'} className="mt-8 lg:!gap-y-12">
          {posts.docs.map((post) => (
            <BlogCard key={post.slug} post={post} />
          ))}
        </RafalGrid>
      </Content>
    </Dashboard>
  );
}
