import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/utils';

const gridVariants = cva('grid w-full', {
  variants: {
    columns: {
      2: 'grid-cols-1 md:grid-cols-2',
      3: 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3',
      4: 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4',
      6: 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6',
    },
    gap: {
      small: 'gap-1 sm:gap-2',
      medium: 'gap-2 sm:gap-4',
      large: 'gap-4 sm:gap-6',
      xl: 'gap-6 sm:gap-8',
    },
  },
  defaultVariants: {
    columns: 2,
    gap: 'medium',
  },
});

export interface GridProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof gridVariants> {}

const Grid = ({ children, columns, gap, className }: GridProps) => {
  return (
    <div className={cn(gridVariants({ columns, gap }), className)}>
      {children}
    </div>
  );
};

export default Grid;
