import { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';

export default function Content(
  props: PropsWithChildren<{
    sx?: BoxProps['sx'];
    customMaxWidth?: number;
  }>,
) {
  return (
    <Box
      maxWidth={props.customMaxWidth || '1500px'}
      height="100%"
      flex={1}
      mx={{
        xs: '0',
        lg: 'auto',
      }}
      width="100%"
    >
      <Box height="100%" width={'100%'}>
        <Box py={{ xs: 2, lg: 8 }} px={{ xs: 2, lg: 4, xxl: 0 }} sx={props.sx}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
