import { PiCaretRight } from 'react-icons/pi';
import { Button } from 'src/shared/ui/button';

import UnstyledLink from 'common/UnstyledLink';

export default function AnalyzeOnChain() {
  return (
    <div className="relative flex h-full min-h-[300px] flex-col overflow-hidden rounded-xl bg-ds-secondary p-8">
      <div className="flex flex-col gap-4">
        <p className="text-ds_l font-medium text-neutral-800">
          Analyze any Web3 community in real-time
        </p>
        <p className="w-2/3 text-ds_s text-ds-text-tertiary">
          {
            'Search for any token, NFT, smart contract or audience by using the On-chain explorer.'
          }
        </p>

        <UnstyledLink href="/explorer" navigateWithinTeam>
          <Button size={'s'} intent={'neutral'}>
            Go to On-chain Explorer
            <PiCaretRight />
          </Button>
        </UnstyledLink>
      </div>

      <div className="pointer-events-none absolute bottom-12 left-0 h-full w-full scale-105">
        <Icons />
      </div>
    </div>
  );
}

function Icons() {
  return (
    <svg viewBox="0 0 567 293" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="462.271"
        y1="-2.18557e-08"
        x2="462.271"
        y2="270"
        stroke="#EDEDED"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.27384 267L4.27373 228.263C4.27373 228.263 34.8046 241.038 44.3671 240.6C53.9296 240.162 72.353 250.117 85.5692 249.493C98.7854 248.87 96.6897 232.268 124.956 230.973C153.222 229.678 145.303 208.342 163.831 207.494C178.903 206.803 183.195 211.835 204.754 210.847C220.15 210.142 224.81 218.16 244.532 217.257C268.425 216.162 271.944 227.316 284.498 226.741C297.051 226.166 306.872 211.389 323.841 209.693C340.811 207.996 348.701 177.109 362.418 175.845C376.135 174.582 390.249 155.83 401.604 155.31C412.96 154.79 424.136 176.512 443.262 175.636C462.387 174.76 465.662 180.896 483.478 180.08C494.801 179.561 507.619 172.688 522.75 171.995C537.882 171.301 563.306 194.422 563.306 194.422L563.306 267L4.27384 267Z"
        fill="url(#paint0_linear_17428_3427)"
      />
      <path
        d="M4.26799 228.14C4.26799 228.14 34.7976 240.884 44.3603 240.445C53.9229 240.007 72.3454 249.939 85.5617 249.315C98.778 248.691 96.684 232.127 124.95 230.832C153.217 229.537 145.3 208.25 163.828 207.401C178.9 206.711 183.192 211.73 204.751 210.743C220.147 210.037 224.806 218.038 244.529 217.134C268.422 216.04 271.94 227.168 284.493 226.593C297.047 226.018 306.869 211.273 323.839 209.579C340.809 207.884 348.702 177.065 362.419 175.803C376.136 174.541 390.252 155.83 401.608 155.31C412.963 154.79 424.138 176.462 443.263 175.586C462.388 174.71 465.663 180.832 483.479 180.016C494.802 179.497 507.621 172.638 522.752 171.944C537.884 171.251 563.306 194.318 563.306 194.318"
        stroke="#08C25F"
        stroke-linecap="round"
      />
      <rect
        x="429.271"
        y="136.5"
        width="64.8571"
        height="22"
        rx="7.04286"
        fill="white"
      />
      <rect
        x="429.271"
        y="136.5"
        width="64.8571"
        height="22"
        rx="7.04286"
        stroke="#E1E1E1"
      />
      <circle cx="439.313" cy="147.5" r="3" fill="#08C25F" />
      <path
        d="M446.825 151C446.865 149.82 447.285 149.01 448.395 148.26L449.695 147.37C450.275 146.98 450.565 146.59 450.565 146C450.565 145.22 450.085 144.79 449.355 144.79C448.595 144.79 448.125 145.25 448.125 146.19V146.35H446.935V146.21C446.935 144.68 447.885 143.76 449.375 143.76C450.815 143.76 451.815 144.61 451.815 145.94C451.815 146.87 451.325 147.62 450.415 148.21L449.355 148.89C448.595 149.38 448.405 149.6 448.365 149.96H451.825V151H446.825ZM457.582 148.41H458.692V149.41H457.582V151H456.382V149.41H453.122V148.21L456.442 143.88H457.582V148.41ZM456.382 148.41V145.62H456.362L454.222 148.41H456.382ZM459.938 151V149.74H461.338V151H459.938ZM462.863 151C462.903 149.82 463.323 149.01 464.433 148.26L465.733 147.37C466.313 146.98 466.603 146.59 466.603 146C466.603 145.22 466.123 144.79 465.393 144.79C464.633 144.79 464.163 145.25 464.163 146.19V146.35H462.973V146.21C462.973 144.68 463.923 143.76 465.413 143.76C466.853 143.76 467.853 144.61 467.853 145.94C467.853 146.87 467.363 147.62 466.453 148.21L465.393 148.89C464.633 149.38 464.443 149.6 464.403 149.96H467.863V151H462.863ZM471.98 151.16C470.18 151.16 469.24 150.15 469.2 148.66H470.4C470.44 149.61 470.99 150.13 471.97 150.13C472.94 150.13 473.42 149.62 473.42 148.91C473.42 148.2 472.94 147.76 472.01 147.76H471.37V146.8H471.85C472.63 146.8 473.06 146.43 473.06 145.8C473.06 145.14 472.61 144.77 471.83 144.77C471.06 144.77 470.62 145.11 470.58 146.05H469.41C469.46 144.54 470.35 143.75 471.88 143.75C473.37 143.75 474.29 144.51 474.29 145.63C474.29 146.31 473.94 146.86 473.22 147.14V147.16C474.18 147.36 474.69 148 474.69 148.92C474.69 150.23 473.67 151.16 471.98 151.16ZM483.977 143.88H485.687V151H484.567V147.33L484.667 144.95L484.167 146.64L482.807 151H481.597L480.157 146.53L479.677 144.91L479.777 147.33V151H478.667V143.88H480.367L481.927 148.68L482.197 149.75L482.467 148.68L483.977 143.88Z"
        fill="#1B1B1B"
      />
      <g filter="url(#filter0_d_17428_3427)">
        <circle cx="461.699" cy="176" r="4" fill="#08C25F" />
        <circle
          cx="461.699"
          cy="176"
          r="5.18466"
          stroke="white"
          stroke-width="2.36932"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_17428_3427"
          x="423.33"
          y="153.631"
          width="76.7383"
          height="76.7388"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="16" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.658824 0 0 0 0 0.658824 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17428_3427"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17428_3427"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_17428_3427"
          x1="403.4"
          y1="259.092"
          x2="398.652"
          y2="155.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#08C25F" stop-opacity="0" />
          <stop offset="0.245" stop-color="#08C25F" stop-opacity="0.05" />
          <stop offset="1" stop-color="#08C25F" stop-opacity="0.25" />
        </linearGradient>
      </defs>
    </svg>
  );
}
