/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import Link from 'next/link';

import { Post } from 'lib/blog/cms/types';

type Props = {
  post: Post;
};

export default function BlogCard({ post }: Props) {
  return (
    <Link href={`https://cookie3.com/blog/${post.slug}`} target="_blank">
      <div className="flex h-full flex-col">
        <div className="bg-quarternary relative aspect-video overflow-hidden rounded-xl">
          <Image
            src={post.coverImage?.url!}
            alt={post.title}
            fill
            className="absolute h-full w-full"
          />
        </div>
        <p className="my-4 text-xs text-ds-text-brand-primary">
          {post.tags[0].name} &nbsp;&middot;&nbsp;{' '}
          {new Date(post.publishedDate).toDateString()}
        </p>
        <p className="text-2xl font-medium text-neutral-950">{post.title}</p>

        <p className="mb-6 mt-2 line-clamp-3 max-w-md text-ds-text-tertiary">
          {post.description}
        </p>

        <button className="ml-auto mt-auto w-max underline underline-offset-2">
          Read more
        </button>
      </div>
    </Link>
  );
}
